/**
 * @generated SignedSource<<09e64a0225fd12d20b8c5e59f066708a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthMethodsGoogleLoginMutation$variables = {
  credential: string;
};
export type AuthMethodsGoogleLoginMutation$data = {
  readonly googleLogin: string | null | undefined;
};
export type AuthMethodsGoogleLoginMutation = {
  response: AuthMethodsGoogleLoginMutation$data;
  variables: AuthMethodsGoogleLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "credential"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "credential",
        "variableName": "credential"
      }
    ],
    "kind": "ScalarField",
    "name": "googleLogin",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthMethodsGoogleLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthMethodsGoogleLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a030ec70d918effac5338fb3f2338e3a",
    "id": null,
    "metadata": {},
    "name": "AuthMethodsGoogleLoginMutation",
    "operationKind": "mutation",
    "text": "mutation AuthMethodsGoogleLoginMutation(\n  $credential: String!\n) {\n  googleLogin(credential: $credential)\n}\n"
  }
};
})();

(node as any).hash = "ec7ff652c6f81602bfb94db62b5b3231";

export default node;

import { FC, useEffect, useMemo, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Popover,
    Select,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Theme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
    ConsumerData,
    EditFormProps,
    ServiceConfigs,
    ServiceConfigsValue,
} from '../data';
import {
    ServiceConfigValueType,
} from '../__generated__/ConsumerAddQuery.graphql';
import { toFloatStr } from '../../../common/format';
import { Form } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { deepEqual } from '../../../common/helpers';

const styles = {
    container: {
        display: 'flex',
    } as SxProps<Theme>,
    valuesContainer: {
        flexGrow: 1,
    } as SxProps<Theme>,
    selectedContainer: {
        minWidth: '450px',
        maxWidth: '70%',
    } as SxProps<Theme>,
    divider: {
        margin: '0 16px',
    } as SxProps<Theme>,
    groupItem: (
        selected: boolean,
        hasErrors: boolean,
        validate: boolean,
    ) => (theme => ({
        padding: '6px 4px 6px 16px',
        cursor: 'pointer',
        color: hasErrors && validate
            ? theme.palette.error.main
            : theme.palette.primary.main,
        background: selected ? theme.palette.background.default : 'unset',
        borderRadius: '8px',
    })) as SxProps<Theme>,
    popover: {
        pointerEvents: 'none',
    } as SxProps<Theme>,
    tableCell: {
        maxWidth: '200px',
    } as SxProps<Theme>,
    restore: {
        position: 'absolute',
        fontSize: '10px',
        top: '-32px',
        right: 0,
    } as SxProps<Theme>,
    inputContainer: {
        width: '100%',
        marginTop: '16px',
        position: 'relative',
    } as SxProps<Theme>,
    inputRestore: (filled: boolean) => ({
        fontSize: '10px',
        position: 'absolute',
        right: filled ? '28px' : '24px',
        top: filled ? 0 : '-14px',
    } as SxProps<Theme>),
    inputComponentContainer: {
        display: 'flex',
        alignItems: 'center',
    } as SxProps<Theme>,
    inputComponent: {
        flexGrow: 1,
        width: '100%',
    } as SxProps<Theme>,
    chip: {
        height: '24px',
        marginRight: '10px',
        marginBottom: '11px',
    } as SxProps<Theme>,
    jsonContainer: (theme => ({
        width: '100%',
        paddingTop: '16px',
        '& .rjsf > div > div': {
            boxShadow: 'none',
            borderRadius: 'none',
            marginTop: '10px',
            '& > div': { padding: 0, paddingTop: '15px' },
            '& .MuiPaper-root': {
                backgroundColor: 'transparent',
                border: `1px solid ${ theme.palette.secondary.main }`,
            },
        },
    })) as SxProps<Theme>,
    jsonTitle: (theme => ({
        fontSize: '10px',
        marginTop: '-24px',
        color: theme.palette.text.primary,
    })) as SxProps<Theme>,
    jsonSubmit: {
        display: 'flex',
        marginTop: '24px',
        justifyContent: 'end',
    } as SxProps<Theme>,
};

export type SettingsProps = Pick<
    EditFormProps,
    'initial'
    | 'data'
    | 'setData'
    | 'serviceConfigs'
    | 'readonly'
    | 'setErrors'
    | 'validate'
> & {
    hideGroups?: boolean;
};

const Settings: FC<SettingsProps> = ({
    initial,
    data,
    setData,
    serviceConfigs,
    readonly,
    setErrors,
    validate,
    hideGroups,
}) => {
    const configsDict = serviceConfigs.reduce((prev, convig) => {
        prev[convig.id] = convig;
        return prev;
    }, {} as { [id: string]: ServiceConfigs[0] });

    const configValuesDict = (data.serviceConfigs || [])
        .reduce((prev, value) => {
            prev[value.id] = value;
            return prev;
        }, {} as { [id: string]: ServiceConfigsValue });

    const initialValuesDict = (initial.serviceConfigs || [])
        .reduce((prev, value) => {
            prev[value.id] = value;
            return prev;
        }, {} as { [id: string]: ServiceConfigsValue });

    const checkInitialAndSetData = (data: ConsumerData) => {
        const isConfigsEqual = (
            value: ServiceConfigsValue,
            initial?: ServiceConfigsValue,
        ) => value.value ===
            (initial?.value || configsDict[value.id].defaultValue);

        setData(
            data.serviceConfigs!.every(configValue => isConfigsEqual(
                configValue,
                initialValuesDict[configValue.id],
            ))
            ? { ...data, serviceConfigs: initial.serviceConfigs }
            : data,
        );
    };

    const groups = serviceConfigs.reduce((prev, config) => {
        if (prev[config.group]) {
            prev[config.group].push(config);
        } else {
            prev[config.group] = [config];
        }

        return prev;
    }, {} as {[group: string]: ServiceConfigs[0][]});

    const [selectedGroup, setSelectedGroup] =
        useState<string | undefined>(Object.keys(groups)[0]);

    const [groupsWithErrors, setGroupsWithErrors] = useState(new Set<string>());

    const valueErrors = useMemo(() => {
        return (data.serviceConfigs || []).reduce((prev, value) => {
            if (
                value.value &&
                value.type === 'Json' &&
                // we shouldn't validate 'null' JSON value
                (value.value || 'null') !== 'null' &&
                !validator.isValid(
                    value.schemaValidation,
                    JSON.parse(value.value),
                    {},
                )) {
                prev[value.id] = 'Invalid JSON';
            }
            return prev;
        }, {} as { [valueId: string]: string });
    }, [data]);

    useEffect(() => {
        setGroupsWithErrors(
            new Set(Object.keys(valueErrors).map(id => configsDict[id].group)),
        );
        setErrors?.([...new Set(Object.values(valueErrors))]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueErrors]);

    const group = hideGroups || !selectedGroup
        ? serviceConfigs
        : groups[selectedGroup]
    ;

    return <Box sx={ styles.container }>
        <Box sx={ styles.valuesContainer }>
            <Box sx={ hideGroups ? {} : styles.selectedContainer }>
                { group.map(config =>
                    <Input
                        key={ config.id }
                        readonly={ readonly }
                        config={ config }
                        configValue={ configValuesDict[config.id] }
                        onChange={ value => checkInitialAndSetData({
                            ...data,
                            serviceConfigs: [
                                ...(data.serviceConfigs || [])
                                    .filter(c => c.id !== config.id),
                                { ...config, value: value },
                            ],
                        }) }
                        validate={ validate }
                        filled={ !!hideGroups }
                    />,
                ) }
            </Box>
        </Box>
        { !hideGroups && <>
            <Divider orientation='vertical' flexItem sx={ styles.divider }/>
            <Box minWidth='240px'>
                { Object
                    .keys(groups)
                    .sort((a, b) => a.localeCompare(b))
                    .map(group => <Box
                        key={ group }
                        sx={ styles.groupItem(
                            selectedGroup === group,
                            groupsWithErrors.has(group),
                            validate,
                        ) }
                        onClick={ () => setSelectedGroup(group) }
                    >
                        { group }
                    </Box>)
                }
            </Box>
        </> }
    </Box>;
};

const Input: FC<{
    config: ServiceConfigs[0];
    configValue: ServiceConfigsValue;
    onChange: (value: string) => void;
    readonly: boolean;
    validate: boolean;
    filled: boolean;
}> = ({ config, configValue, onChange, readonly, validate, filled }) => {
    const {
        name,
        displayedName,
        description,
        type,
        possibleValues,
        min,
        max,
        defaultValue,
        schemaValidation,
    } = config;
    const { value } = configValue || {};

    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();

    const getPopoverCell = (title: string, value: any) => {
        return (!!value || value === 0) && <TableRow>
            <TableCell sx={ styles.tableCell }>
                { title }
            </TableCell>
            <TableCell sx={ styles.tableCell }>
                { value }
            </TableCell>
        </TableRow>;
    }

    return <Box sx={ styles.inputContainer }>
        <Button
            sx={ styles.inputRestore(filled) }
            disabled={ (!value && !defaultValue) || value === defaultValue }
            onClick={ () => onChange(defaultValue) }
        >
            Restore
        </Button>
        <Box sx={ styles.inputComponentContainer }>
            <InputComponent
                displayName={ displayedName || name }
                value={ value === undefined || value === null
                    ? defaultValue
                    : value
                }
                type={ type }
                possibleValues={ possibleValues || undefined }
                min={ min === null ? undefined : min }
                max={ max === null ? undefined : max }
                schemaValidation={ schemaValidation }
                onChange={ onChange }
                readonly={ readonly }
                validate={ validate }
                filled={ filled }
            />
            <IconButton
                size='small'
                onMouseEnter={ e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAnchorEl(e.currentTarget);
                    document
                        .querySelectorAll('div[role=tooltip]')
                        .forEach(item => (item as any).style.display = 'none');
                } }
                onMouseLeave={ () => setAnchorEl(undefined) }
            >
                <InfoIcon fontSize='small' />
            </IconButton>
        </Box>
        <Popover
            sx={ styles.popover }
            open={ !!anchorEl }
            anchorEl={ anchorEl }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={ () => setAnchorEl(undefined) }
            disableRestoreFocus
            disableScrollLock
        >
            <Paper>
                <Table size='small'>
                    <TableBody>
                        { getPopoverCell('Description', description) }
                        { getPopoverCell('Type', type) }
                        { getPopoverCell('Possible Values', possibleValues) }
                        { getPopoverCell('Maximum Value', max) }
                        { getPopoverCell('Minimum Value', min) }
                    </TableBody>
                </Table>
            </Paper>
        </Popover>
    </Box>;
}

const InputComponent: FC<{
    displayName: string;
    value: string;
    type: ServiceConfigValueType;
    possibleValues: string | undefined;
    min: number | undefined;
    max: number | undefined;
    schemaValidation: any,
    onChange: (value: string) => void;
    readonly: boolean;
    validate: boolean;
    filled: boolean;
}> = ({
    displayName,
    value,
    type,
    possibleValues,
    min,
    max,
    schemaValidation,
    onChange,
    readonly,
    validate,
    filled,
}) => {
    const parseArrValues = (values: string | undefined) => values
        ?.split(',')
        .map(v => v.trim())
        .filter(v => v) || [];

    const possibleValuesArr = parseArrValues(possibleValues);

    if (possibleValues && type !== 'Array') {
        return <FormControl sx={ styles.inputComponent }>
            <InputLabel variant={ filled ? 'filled' : 'standard' }>
                { displayName }
            </InputLabel>
            <Select
                variant={ filled ? 'filled' : 'standard' }
                disableUnderline={ filled }
                disabled={ readonly }
                value={ value }
                onChange={ e => onChange(e.target.value) }
            >
                { possibleValuesArr.map( value =>
                    <MenuItem key={ value } value={ value }>
                        { value }
                    </MenuItem>
                ) }
            </Select>
        </FormControl>;
    }

    switch (type) {
        case 'Boolean':
            return <FormControl
                sx={ styles.inputComponent }
            >
                <InputLabel
                    variant={ filled ? 'filled' : 'standard' }
                    placeholder={ displayName  }
                    sx={{
                        borderRadius: '10px',
                    }}
                >
                    { displayName }
                </InputLabel>
                <Select
                    variant={ filled ? 'filled' : 'standard' }
                    disableUnderline={ filled }
                    disabled={ readonly }
                    value={ value }
                    onChange={ e => onChange(e.target.value) }
                >
                    <MenuItem value='true'>true</MenuItem>
                    <MenuItem value='false'>false</MenuItem>
                </Select>
            </FormControl>;
        case 'Int':
        case 'Float':
            return <form noValidate autoComplete='off' style={{ flexGrow: 1 }}>
                <TextField
                    variant={ filled ? 'filled' : 'standard' }
                    disabled={ readonly }
                    sx={ styles.inputComponent }
                    type='number'
                    label={ displayName }
                    placeholder={ filled ? displayName : undefined }
                    value={ value }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={ ({ target: { value } }) => {
                        let num = type === 'Int'
                            ? parseInt(value)
                            : +toFloatStr(value);

                        num = min === undefined ? num : Math.max(min, num);
                        num = max === undefined ? num : Math.min(max, num);

                        onChange(String(num));
                    } }
                    inputProps={{ type: 'number', min, max }}
                />
            </form>;
        case 'Array':
            return <Autocomplete
                sx={ styles.inputComponent }
                disabled={ readonly }
                multiple
                autoComplete
                freeSolo
                clearOnBlur
                options={ possibleValuesArr }
                value={ parseArrValues(value) }
                onChange={ (_, values: string[]) => {
                    const filtered = possibleValuesArr.length
                        ? values.filter(i => possibleValuesArr.includes(i))
                        : values;

                    onChange(filtered.join(', '));
                } }
                onBlur={ e => {
                    const added = (e.target as any).value;
                    if (
                        added &&
                        (
                            !possibleValuesArr.length ||
                            possibleValuesArr.includes(added)
                        )
                    ) {
                        onChange(`${ value }, ${ added }`);
                    }
                } }
                renderTags={ (values: string[], getTagProps) => values.map(
                    (option: string, index: number) => <Chip
                        size='small'
                        sx={ styles.chip }
                        label={ option }
                        { ...getTagProps({ index }) }
                    /> )
                }
                renderInput={ params => <TextField
                    label={ displayName }
                    { ...params }
                    variant={ filled ? 'filled' : 'standard' }
                    placeholder={ filled ? displayName : undefined }
                    InputLabelProps={{
                        shrink: true,
                    }}
                />}
            />;
        case 'Json':
            return <JsonEditor
                displayName={ displayName }
                value={ value }
                schemaValidation={ schemaValidation }
                onChange={ onChange }
                readonly={ readonly }
                validate={ validate }
            />;
        case 'String':
        default:
            return <form noValidate autoComplete='off' style={{ flexGrow: 1 }}>
                <TextField
                    disabled={ readonly }
                    sx={ styles.inputComponent }
                    label={ displayName }
                    multiline
                    variant={ filled ? 'filled' : 'standard' }
                    placeholder={ filled ? displayName : undefined }
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={ value }
                    onChange={ e => onChange(e.target.value) }
                />
            </form>;
    }
};

const JsonEditor: FC<{
    displayName: string;
    value: string;
    schemaValidation: any,
    onChange: (value: string) => void;
    readonly: boolean;
    validate: boolean;
}> = ({
    displayName,
    value,
    schemaValidation,
    onChange,
    readonly,
    validate,
}) => {
    const [json, setJson] = useState<any>(JSON.parse(value));
    const [changeValueToken, setChangeValueToken] = useState(0);

    useEffect(() => {
        const parsed = JSON.parse(value);
        if (!deepEqual(parsed, JSON.parse(JSON.stringify(json)))) {
            setJson(parsed);
            setChangeValueToken(Date.now())
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(
        () => onChange(JSON.stringify(json)),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [json],
    );

    return <FormControl sx={ styles.inputComponent }>
        <InputLabel variant='standard' sx={ styles.jsonTitle }>
            { displayName }
        </InputLabel>
        <Box sx={ styles.jsonContainer }>
            <Form
                // need recreate JSON Form to reset validation state
                // (it leaves errors after click on 'reset' button)
                key={ String(changeValueToken) }
                disabled={ readonly }
                validator={ validator }
                liveValidate={ validate }
                showErrorList={ false }
                schema={ schemaValidation || {} }
                formData={ json }
                onChange={ data => setJson(data.formData) }
                children={ <></> } // removes submit button
            />
        </Box>
    </FormControl>;
}

export default Settings;

/**
 * @generated SignedSource<<130e2583c5fdd4e535beff1b1aeadab3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DecimalSeparator = "Comma" | "Point" | "%future added value";
export type ThousandSeparator = "Comma" | "Point" | "Prime" | "Space" | "%future added value";
export type WizardInput = {
  adminUser: WizardAdminUserInput;
  consumer: WizardConsumerInput;
  networks?: ReadonlyArray<WizardNetworkInput> | null | undefined;
};
export type WizardConsumerInput = {
  accountableLoanCategories?: ReadonlyArray<AccountableLoanCategoryEnableInput> | null | undefined;
  companyId?: string | null | undefined;
  consumerFeatures?: ReadonlyArray<ConsumerFeatureCreateInput> | null | undefined;
  consumerIntegrations?: ReadonlyArray<ConsumerIntegrationCreateInput> | null | undefined;
  customFormat?: CustomFormatInput | null | undefined;
  description?: string | null | undefined;
  locale?: string | null | undefined;
  name: string;
  requestLimit?: number | null | undefined;
  serviceConfigs?: ReadonlyArray<ServiceConfigCreateInput> | null | undefined;
  timezone?: string | null | undefined;
};
export type CustomFormatInput = {
  currency?: CurrencyFormatInput | null | undefined;
  date?: DateTimeFormatInput | null | undefined;
  dateTime?: DateTimeFormatInput | null | undefined;
  measurement?: MeasurementFormatInput | null | undefined;
  numbers?: NumberFormatInput | null | undefined;
  time?: DateTimeFormatInput | null | undefined;
};
export type DateTimeFormatInput = {
  format?: string | null | undefined;
};
export type NumberFormatInput = {
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
};
export type CurrencyFormatInput = {
  currency?: string | null | undefined;
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
};
export type MeasurementFormatInput = {
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
  unit?: string | null | undefined;
};
export type ConsumerFeatureCreateInput = {
  featureId: string;
};
export type ConsumerIntegrationCreateInput = {
  enabled: boolean;
  headers?: ReadonlyArray<HeaderValueCreateInput> | null | undefined;
  integrationId: string;
  params?: ReadonlyArray<ParamValueCreateInput> | null | undefined;
};
export type ParamValueCreateInput = {
  paramId: string;
  value: string;
};
export type HeaderValueCreateInput = {
  headerId: string;
  value: string;
};
export type AccountableLoanCategoryEnableInput = {
  categoryId: string;
  enabled: boolean;
};
export type ServiceConfigCreateInput = {
  serviceConfigId: string;
  value: string;
};
export type WizardAdminUserInput = {
  customFormat?: CustomFormatInput | null | undefined;
  email: string;
  firstName: string;
  lastName: string;
  locale?: string | null | undefined;
  passwordHash: string;
  phone?: string | null | undefined;
  timezone?: string | null | undefined;
};
export type WizardNetworkInput = {
  cidr?: string | null | undefined;
  description?: string | null | undefined;
};
export type WizardDataProviderMutation$variables = {
  input: WizardInput;
};
export type WizardDataProviderMutation$data = {
  readonly wizardInstallation: boolean | null | undefined;
};
export type WizardDataProviderMutation = {
  response: WizardDataProviderMutation$data;
  variables: WizardDataProviderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "wizardInstallation",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WizardDataProviderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WizardDataProviderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "507e3bd90dfea117656d7e96e727a04d",
    "id": null,
    "metadata": {},
    "name": "WizardDataProviderMutation",
    "operationKind": "mutation",
    "text": "mutation WizardDataProviderMutation(\n  $input: WizardInput!\n) {\n  wizardInstallation(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "c480de08fd484522095fab8530d09238";

export default node;

/**
 * @generated SignedSource<<357a518cf1d3dd7089bc661dacae9e27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KnownFunctionality = "Clp" | "Failures" | "LiveScore" | "Lms" | "%future added value";
export type AppMenuQuery$variables = Record<PropertyKey, never>;
export type AppMenuQuery$data = {
  readonly functionalityEnabled: ReadonlyArray<{
    readonly enabled: boolean;
    readonly id: string;
    readonly name: KnownFunctionality;
  }>;
};
export type AppMenuQuery = {
  response: AppMenuQuery$data;
  variables: AppMenuQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "functionalities",
        "value": [
          "Lms",
          "Clp",
          "Failures"
        ]
      }
    ],
    "concreteType": "KnownFunctionalityValidation",
    "kind": "LinkedField",
    "name": "functionalityEnabled",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      }
    ],
    "storageKey": "functionalityEnabled(functionalities:[\"Lms\",\"Clp\",\"Failures\"])"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppMenuQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppMenuQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "10e058f9a0ef667099d7cefcf5c560b5",
    "id": null,
    "metadata": {},
    "name": "AppMenuQuery",
    "operationKind": "query",
    "text": "query AppMenuQuery {\n  functionalityEnabled(functionalities: [Lms, Clp, Failures]) {\n    id\n    name\n    enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7bae44233bca141099cd91ef0d1cd52";

export default node;

/**
 * @generated SignedSource<<f287216534395233963d3fbd751384b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DownloadLoanAgentReleasesQuery$variables = Record<PropertyKey, never>;
export type DownloadLoanAgentReleasesQuery$data = {
  readonly loanAgentLatestRelease: {
    readonly assets: ReadonlyArray<{
      readonly contentType: string;
      readonly createdAt: any;
      readonly description: string;
      readonly downloadCount: number;
      readonly id: string;
      readonly name: string;
      readonly platform: string;
      readonly size: number;
      readonly state: string;
      readonly updatedAt: any;
      readonly version: string;
    }>;
    readonly createdAt: any;
    readonly description: string;
    readonly id: string;
    readonly name: string;
    readonly publishedAt: any;
  };
  readonly loanAgentReleases: ReadonlyArray<{
    readonly assets: ReadonlyArray<{
      readonly contentType: string;
      readonly createdAt: any;
      readonly description: string;
      readonly downloadCount: number;
      readonly id: string;
      readonly name: string;
      readonly platform: string;
      readonly size: number;
      readonly state: string;
      readonly updatedAt: any;
      readonly version: string;
    }>;
    readonly createdAt: any;
    readonly description: string;
    readonly id: string;
    readonly name: string;
    readonly publishedAt: any;
  }>;
};
export type DownloadLoanAgentReleasesQuery = {
  response: DownloadLoanAgentReleasesQuery$data;
  variables: DownloadLoanAgentReleasesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "LoanAgentReleaseAsset",
    "kind": "LinkedField",
    "name": "assets",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "platform",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contentType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloadCount",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "publishedAt",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LoanAgentRelease",
    "kind": "LinkedField",
    "name": "loanAgentLatestRelease",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LoanAgentRelease",
    "kind": "LinkedField",
    "name": "loanAgentReleases",
    "plural": true,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloadLoanAgentReleasesQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DownloadLoanAgentReleasesQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "ac16aa31ddbd09efb4a10812d947e13a",
    "id": null,
    "metadata": {},
    "name": "DownloadLoanAgentReleasesQuery",
    "operationKind": "query",
    "text": "query DownloadLoanAgentReleasesQuery {\n  loanAgentLatestRelease {\n    id\n    name\n    description\n    assets {\n      id\n      name\n      platform\n      version\n      contentType\n      size\n      downloadCount\n      createdAt\n      updatedAt\n      description\n      state\n    }\n    createdAt\n    publishedAt\n  }\n  loanAgentReleases {\n    id\n    name\n    description\n    assets {\n      id\n      name\n      platform\n      version\n      contentType\n      size\n      downloadCount\n      createdAt\n      updatedAt\n      description\n      state\n    }\n    createdAt\n    publishedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d562318785734a92260d527f7c8a9da";

export default node;

/**
 * @generated SignedSource<<b450315689dda8bc8b0796550b757d6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginFormMutation$variables = {
  email: string;
  passwordHash: string;
};
export type LoginFormMutation$data = {
  readonly login: string | null | undefined;
};
export type LoginFormMutation = {
  response: LoginFormMutation$data;
  variables: LoginFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passwordHash"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "passwordHash",
        "variableName": "passwordHash"
      }
    ],
    "kind": "ScalarField",
    "name": "login",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "548e61acdb7b136ba891b70cc7321647",
    "id": null,
    "metadata": {},
    "name": "LoginFormMutation",
    "operationKind": "mutation",
    "text": "mutation LoginFormMutation(\n  $email: String!\n  $passwordHash: String!\n) {\n  login(email: $email, passwordHash: $passwordHash)\n}\n"
  }
};
})();

(node as any).hash = "0016b12847a63d3ea0c6838d44219da2";

export default node;

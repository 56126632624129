import { Box, Divider, SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';

const styles = {
    container: {
        display: 'flex',
        padding: '6px 0',
    } as SxProps<Theme>,
    title: (theme => ({
        color: theme.palette.text.disabled,
        minWidth: '196px'
    })) as SxProps<Theme>,
    value: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    } as SxProps<Theme>,
};

const ReadonlyInfoItem: FC<{
    title: string;
    value: string,
    showDivider?: boolean;
}> = (
    { title, value, showDivider },
) => {
    return <>
        { showDivider !== false && <Divider /> }
        <Box sx={ styles.container }>
            <Typography sx={ styles.title }>{ title }</Typography>
            <Typography sx={ styles.value }>{ value }</Typography>
        </Box>
    </>;
}

export default ReadonlyInfoItem;

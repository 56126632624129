import React, { FC, useState } from 'react';
import Background from '../components/Background';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { isDark, isLight } from '../common/theme';
import Typography from '@mui/material/Typography';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Button from '@mui/material/Button';
import BrandTitle from '../components/BrandTitle';
import WizardSetup from '../components/WizardSetup';
import { useWizard } from '../components/WizardSetup/hooks/WizardProvider';
import ErrorIcon from '@mui/icons-material/WarningAmberRounded';

const styles = {
    mainButton: (theme: Theme) => ({
        color: isLight(theme)
            ? theme.palette.primary.main
            : theme.palette.background.default,
        backgroundColor: isLight(theme)
            ? theme.palette.secondary.light
            : theme.palette.primary.main,
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.common.white
                : theme.palette.primary.light,
            boxShadow: isLight(theme)
                ? '0px 2px 4px #1E2A44'
                : '0px 2px 4px #00369C',
        },
        fontSize: '16px',
        width: '192px',
        height: '34px',
        boxShadow: isDark(theme)
            ? '0px 2px 4px #1E2A44'
            : '0px 2px 4px #00369C',
        margin: '35px 0',
    }),
};

interface InitialContentProps {
    onStart?: () => void;
}

const InitialContent: FC<InitialContentProps> = props => {
    const { error } = useWizard();
    const Icon = error ? ErrorIcon : PersonAddAltIcon;
    const text = error
        ? 'Error occurred'
        : 'New client setup wizard'
    ;

    return <Box
        display={ 'flex' }
        flexDirection={ 'column' }
        justifyContent={ 'center' }
        alignItems={ 'center' }
    >
        <Icon sx={{ fontSize: '86px' }}/>
        <Typography
            fontSize={ '36px' }
            fontWeight={ 500 }
            padding={ '13px 0' }
        >
            { text }
        </Typography>
        { error && <Typography>
            Please, try to make setup again or contact support
        </Typography> }
        <Button
            size={ 'medium' }
            color={ 'primary' }
            variant={ 'contained' }
            disableElevation
            sx={ styles.mainButton }
            onClick={ props.onStart }
        >
           Start setup
        </Button>
    </Box>;
};

export const Wizard: FC = () => {
    const { error, reset } = useWizard();
    const [initial, setInitial] = useState<boolean>(true);

    if (!initial && !error) {
        return <Box
            display={ 'flex' }
            width={ '100%' }
            height={ '100%' }
        >
            <WizardSetup/>
        </Box>;
    }

    return <Background
        size={ 'large' }
        color={ 'primary' }
        sx={{ overflow: 'hidden' }}
    >
        <Box
            overflow={ 'hidden' }
            width={ '100%' }
            height={ '100%' }
        >
            <Box
                padding={ '40px' }
                width={ '100%' }
                height={ '100%' }
            >
                <BrandTitle/>
                <Box
                    width={ '100%' }
                    height={ '100%' }
                >
                    <Box
                        display={ 'flex' }
                        justifyContent={ 'center' }
                        alignItems={ 'center' }
                        height={ 'calc(100% - 203px)' }
                    >
                        <InitialContent onStart={ () => {
                            reset();
                            setInitial(false);
                        } }/>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Background>;
};

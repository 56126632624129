/**
 * @generated SignedSource<<5f37dea99ba9f4287c68e5ee1084b4fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PhoneCheckQuery$variables = {
  phone: string;
};
export type PhoneCheckQuery$data = {
  readonly isUserPhoneUsed: boolean;
};
export type PhoneCheckQuery = {
  response: PhoneCheckQuery$data;
  variables: PhoneCheckQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "phone"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "phone",
        "variableName": "phone"
      }
    ],
    "kind": "ScalarField",
    "name": "isUserPhoneUsed",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PhoneCheckQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PhoneCheckQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8604eb970a16b97700447309d3e718d6",
    "id": null,
    "metadata": {},
    "name": "PhoneCheckQuery",
    "operationKind": "query",
    "text": "query PhoneCheckQuery(\n  $phone: String!\n) {\n  isUserPhoneUsed(phone: $phone)\n}\n"
  }
};
})();

(node as any).hash = "9545f2b0f174d85f77326b3f28b3bbfb";

export default node;

import { FC, ReactNode, useMemo, useState } from 'react';
import { EditFormProps } from '../data';
import EnablingList from './EnablingList';
import { SxProps, Theme, Typography } from '@mui/material';
import ConfirmActionDialog from '../../ConfirmActionDialog';
import { Features as FeaturesData } from '../data';

const styles = {
    title: (theme: Theme) => ({
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '16px',
        fontWeight: 500,
    }),
    subtitle: (theme => ({
        color: theme.palette.text.disabled,
        display: 'inline',
    })) as SxProps<Theme>,
}

const Features: FC<Pick<
    EditFormProps,
    'initial'
    | 'data'
    | 'setData'
    | 'features'
    | 'readonly'
>> = (
    { initial, data, setData, features, readonly },
) => {
    const featuresDict = features.reduce((prev, feature) => {
        prev[feature.id] = feature;
        return prev;
    }, {} as { [id: string]: FeaturesData[0] });

    const [confirmation, setConfirmation] = useState<ReactNode | undefined>();

    const sortedFeatures = useMemo(
        () => [...features].sort((a, b) => a.name.localeCompare(b.name)),
        [features],
    );

    const setSelectedIds = async (
        selectedIds: Set<string>,
        selected: boolean,
        id: string,
    ) => {
        const feature = featuresDict[id];

        const confirm = (dependants: FeaturesData) => new Promise<boolean>(
            resolve => setConfirmation(<ConfirmActionDialog
                open={ true }
                text={
                    `All related features of ${
                        feature.name
                    } will be switched ${
                        selected ? 'on' : 'off'
                    } (${
                        dependants.map(p => p.name).join(', ')
                    }). Are you sure you want to continue?`
                }
                onConfirm={ () => {
                    resolve(true);
                    setConfirmation(undefined);
                } }
                onCancel={ () => {
                    resolve(false);
                    setConfirmation(undefined);
                } }
            />),
        );

        if (selected) {
            const getParentIds = (feature: FeaturesData[0]): string[] =>
                feature.parents?.reduce<string[]>((prev, { id }) => [
                    ...prev,
                    id,
                    ...getParentIds(featuresDict[id]),
                ], []) || [];

            const unselectedParents =  [...new Set(getParentIds(feature))]
                .filter(id => !selectedIds.has(id))
                .map(id => featuresDict[id]);

            if (unselectedParents.length) {
                if (!await confirm(unselectedParents)) {
                    return;
                }

                selectedIds = new Set([
                    ...selectedIds,
                    ...unselectedParents.map(p => p.id),
                ]);
            }
        } else {
            const getChildrenIds = (id: string): string[] => features
                .filter(f => f.parents && f.parents.find(p => p.id === id))
                .reduce<string[]>(
                    (prev, { id }) => [ ...prev, id, ...getChildrenIds(id) ],
                    [],
                );

            const selectedChildren = [...new Set(getChildrenIds(id))]
                .filter(id => selectedIds.has(id))
                .map(id => featuresDict[id]);

            if (selectedChildren.length) {
                if (!await confirm(selectedChildren)) {
                    return;
                }

                const selectedChildrenSet =
                    new Set(selectedChildren.map(c => c.id));
                selectedIds = new Set(
                    [...selectedIds].filter(id => !selectedChildrenSet.has(id)),
                );
            }
        }

        const getSorted = (arr: string[]) =>
            arr.sort((a, b) => a.localeCompare(b));

        const sortedInitial =
            getSorted(initial.features?.map(f => f.id) || []);
        const sorted = getSorted([...selectedIds]);

        setData({
            ...data,
            features: sortedInitial.length === sorted.length &&
                sortedInitial.every((c, i) => c === sorted[i])
                    ? initial.features
                    : sorted.map(id => ({ id })),
        });
    };

    return <>
        <EnablingList
            items={ sortedFeatures.map(feature => ({
                id: feature.id,
                title: <Typography component={ 'span' } sx={ styles.title }>
                        { feature.name }
                        <Typography sx={ styles.subtitle }>
                            { ` | ${ feature.description }` }
                        </Typography>
                    </Typography>
            })) }
            selectedIds={new Set((data.features || []).map(f => f.id))}
            setSelectedIds={ setSelectedIds }
            readonly={ readonly }
        />
        { confirmation }
    </>;
};

export default Features;

import { Palette, Theme } from '@mui/material/styles';
import { AvailableThemes, KnownThemes } from '../themes';

export function isTheme(
    theme: Theme | Palette,
    which: keyof AvailableThemes,
): boolean {
    const mode = theme && (theme as Theme).palette
        ? (theme as Theme).palette.mode
        : (theme && (theme as Palette).mode)
            ? (theme as Palette).mode
            : KnownThemes.light
    ;

    return mode === which;
}

export function isDark(theme: Theme | Palette): boolean {
    return isTheme(theme, KnownThemes.dark);
}

export function isLight(theme: Theme | Palette): boolean {
    return isTheme(theme, KnownThemes.light);
}

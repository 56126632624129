import { Box, Divider, Switch, SxProps, Theme } from '@mui/material';
import { FC, Fragment, ReactNode } from 'react';

const styles = {
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
    } as SxProps<Theme>,
    divider: {
        margin: '8px 0',
    } as SxProps<Theme>,
}

export interface EnablingListProps {
    items: { id: string; title: ReactNode; }[];
    selectedIds: Set<string>;
    setSelectedIds: (
        selectedIds: Set<string>,
        selected: boolean,
        id: string,
    ) => void;
    readonly: boolean;
}

const EnablingList: FC<EnablingListProps> = ({
    items,
    selectedIds,
    setSelectedIds,
    readonly,
}) => {
    return <> { items.map((item, i) =>
        <Fragment key={ item.id }>
            <Box sx={ styles.item }>
                { item.title }
                <Switch
                    checked={ selectedIds.has(item.id) }
                    disabled={ readonly }
                    onChange={ (_, checked) => setSelectedIds(
                        new Set( checked
                            ? [...selectedIds, item.id]
                            : [...selectedIds].filter(id => id !== item.id),
                        ),
                        checked,
                        item.id,
                    ) }
                />
            </Box>
            { i < items.length - 1 && <Divider sx={ styles.divider } /> }
        </Fragment>
    ) } </>;
}

export default EnablingList;

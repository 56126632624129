/**
 * @generated SignedSource<<21602605a67503c2b9250db5e0b5a8e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReportIdFieldVendorsQuery$variables = {
  consumerId?: string | null | undefined;
};
export type ReportIdFieldVendorsQuery$data = {
  readonly vendors: {
    readonly all: ReadonlyArray<string>;
    readonly default: string | null | undefined;
  };
};
export type ReportIdFieldVendorsQuery = {
  response: ReportIdFieldVendorsQuery$data;
  variables: ReportIdFieldVendorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "consumerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "consumerId",
        "variableName": "consumerId"
      }
    ],
    "concreteType": "Vendors",
    "kind": "LinkedField",
    "name": "vendors",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "all",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "default",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportIdFieldVendorsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportIdFieldVendorsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "532802a0c92a09c7ca1936fb8c4e8ce4",
    "id": null,
    "metadata": {},
    "name": "ReportIdFieldVendorsQuery",
    "operationKind": "query",
    "text": "query ReportIdFieldVendorsQuery(\n  $consumerId: ID\n) {\n  vendors(consumerId: $consumerId) {\n    all\n    default\n  }\n}\n"
  }
};
})();

(node as any).hash = "d278ef516a773d62573546d2df854862";

export default node;

/**
 * @generated SignedSource<<02dbdb075b961e3460037fadbecab580>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type srcValidateAuthTokenQuery$variables = Record<PropertyKey, never>;
export type srcValidateAuthTokenQuery$data = {
  readonly validateAuthToken: {
    readonly isValid: boolean;
  };
};
export type srcValidateAuthTokenQuery = {
  response: srcValidateAuthTokenQuery$data;
  variables: srcValidateAuthTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ValidatedAuthToken",
    "kind": "LinkedField",
    "name": "validateAuthToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isValid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "srcValidateAuthTokenQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "srcValidateAuthTokenQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1629d2c940efc047c690dc50ef6be794",
    "id": null,
    "metadata": {},
    "name": "srcValidateAuthTokenQuery",
    "operationKind": "query",
    "text": "query srcValidateAuthTokenQuery {\n  validateAuthToken {\n    isValid\n  }\n}\n"
  }
};
})();

(node as any).hash = "350aefd4b1bbb8204f55b0beaa4eb5ed";

export default node;

import { Theme } from '@mui/material';
import { isDark } from '../common/theme';
import Box from '@mui/material/Box';
import Logo from '../assets/SvgIcons/Logo';
import Typography from '@mui/material/Typography';
import React from 'react';

const styles = {
    logo: (theme: Theme) => ({
        fontSize: '43px !important',
        color: (
            isDark(theme)
                ? theme.palette.primary.main
                : theme.palette.getContrastText(
                    theme.palette.primary.main,
                )
        ) + ' !important',
    }),
    name: (theme: Theme) => ({
        paddingTop: '2px !important',
        fontSize: '22px !important',
        fontWeight: '600 !important',
        color: (
            isDark(theme)
                ? theme.palette.primary.main
                : theme.palette.getContrastText(
                    theme.palette.primary.main,
                )
        ) + ' !important',
    }),
};

const BrandTitle = () => {
    return <Box display={ 'flex' } gap={ '14px' } alignItems={ 'center' }>
        <Logo color={ 'primary' } sx={ styles.logo }/>
        <Typography color={ 'primary.main' } sx={ styles.name }>
            Credit Sense
        </Typography>
    </Box>;
};

export default BrandTitle;

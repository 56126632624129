/**
 * @generated SignedSource<<80067cd6086fd3e2d2cf7800255c1d48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DecimalSeparator = "Comma" | "Point" | "%future added value";
export type ThousandSeparator = "Comma" | "Point" | "Prime" | "Space" | "%future added value";
export type UserRegistrationInput = {
  active: boolean;
  customFormat?: CustomFormatInput | null | undefined;
  email: string;
  firstName: string;
  lastName: string;
  locale?: string | null | undefined;
  passwordHash: string;
  phone?: string | null | undefined;
  roles?: ReadonlyArray<UserRoleRegistrationInput> | null | undefined;
  timezone?: string | null | undefined;
};
export type CustomFormatInput = {
  currency?: CurrencyFormatInput | null | undefined;
  date?: DateTimeFormatInput | null | undefined;
  dateTime?: DateTimeFormatInput | null | undefined;
  measurement?: MeasurementFormatInput | null | undefined;
  numbers?: NumberFormatInput | null | undefined;
  time?: DateTimeFormatInput | null | undefined;
};
export type DateTimeFormatInput = {
  format?: string | null | undefined;
};
export type NumberFormatInput = {
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
};
export type CurrencyFormatInput = {
  currency?: string | null | undefined;
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
};
export type MeasurementFormatInput = {
  decimalSeparator?: DecimalSeparator | null | undefined;
  grouping?: boolean | null | undefined;
  thousandSeparator?: ThousandSeparator | null | undefined;
  unit?: string | null | undefined;
};
export type UserRoleRegistrationInput = {
  consumerId: string;
  roleId: string;
};
export type UserFormRegisterMutation$variables = {
  input: UserRegistrationInput;
  passwordHash: string;
};
export type UserFormRegisterMutation$data = {
  readonly register: {
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
  };
};
export type UserFormRegisterMutation = {
  response: UserFormRegisterMutation$data;
  variables: UserFormRegisterMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passwordHash"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "passwordHash",
        "variableName": "passwordHash"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "register",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserFormRegisterMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserFormRegisterMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6d9a432c18f5ec9b961c9b94fb265c2a",
    "id": null,
    "metadata": {},
    "name": "UserFormRegisterMutation",
    "operationKind": "mutation",
    "text": "mutation UserFormRegisterMutation(\n  $input: UserRegistrationInput!\n  $passwordHash: String!\n) {\n  register(input: $input, passwordHash: $passwordHash) {\n    id\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "7818921e17fb39bdb804f0a1b552b75f";

export default node;

/**
 * @generated SignedSource<<711d64148c999167542d85073008139a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDirection = "Asc" | "Desc" | "%future added value";
export type Ownership = "All" | "Consumer" | "User" | "%future added value";
export type Permission = "Allow" | "Deny" | "%future added value";
export type RoleListFilter = {
  baseKind?: Ownership | null | undefined;
  basePermission?: Permission | null | undefined;
  createdAt?: string | null | undefined;
  createdAtRange?: DateRange | null | undefined;
  description?: string | null | undefined;
  external?: boolean | null | undefined;
  id?: ReadonlyArray<string> | null | undefined;
  name?: string | null | undefined;
  parentId?: ReadonlyArray<string> | null | undefined;
  priority?: number | null | undefined;
  updatedAt?: string | null | undefined;
  updatedAtRange?: DateRange | null | undefined;
};
export type DateRange = {
  end: any;
  start: any;
};
export type RoleOrderArg = {
  baseKind?: PositionedOrderDirection | null | undefined;
  basePermission?: PositionedOrderDirection | null | undefined;
  description?: PositionedOrderDirection | null | undefined;
  name?: PositionedOrderDirection | null | undefined;
  priority?: PositionedOrderDirection | null | undefined;
};
export type PositionedOrderDirection = {
  direction?: OrderDirection | null | undefined;
  position?: number | null | undefined;
};
export type RolePaginatedListQuery$variables = {
  after?: string | null | undefined;
  filter: RoleListFilter;
  first?: number | null | undefined;
  orderBy?: RoleOrderArg | null | undefined;
};
export type RolePaginatedListQuery$data = {
  readonly roles: {
    readonly " $fragmentSpreads": FragmentRefs<"RolePaginatedList_data">;
  };
};
export type RolePaginatedListQuery = {
  response: RolePaginatedListQuery$data;
  variables: RolePaginatedListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RolePaginatedListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "RoleConnection",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RolePaginatedList_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RolePaginatedListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "RoleConnection",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RoleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72d5700a3cbdb48f1f1b8b36f7158e50",
    "id": null,
    "metadata": {},
    "name": "RolePaginatedListQuery",
    "operationKind": "query",
    "text": "query RolePaginatedListQuery(\n  $filter: RoleListFilter!\n  $orderBy: RoleOrderArg\n  $first: Int\n  $after: String\n) {\n  roles(filter: $filter, orderBy: $orderBy, first: $first, after: $after) {\n    ...RolePaginatedList_data\n  }\n}\n\nfragment RolePaginatedList_data on RoleConnection {\n  pageInfo {\n    startCursor\n    endCursor\n    total\n  }\n  edges {\n    node {\n      id\n      name\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "528dc7a1e11701bc47da38f1df840105";

export default node;

/**
 * @generated SignedSource<<3577d859cbc809f34ec66721c470f1f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResetPasswordMutation$variables = {
  emailOrPhone: string;
  token: string;
};
export type ResetPasswordMutation$data = {
  readonly resetPassword: boolean;
};
export type ResetPasswordMutation = {
  response: ResetPasswordMutation$data;
  variables: ResetPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "emailOrPhone"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "emailOrPhone",
        "variableName": "emailOrPhone"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ScalarField",
    "name": "resetPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3ada4e13be97cb7f8a9621d0737ffa15",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordMutation(\n  $emailOrPhone: String!\n  $token: String!\n) {\n  resetPassword(emailOrPhone: $emailOrPhone, token: $token)\n}\n"
  }
};
})();

(node as any).hash = "e12658bee76bcdb37d445dd3dc623348";

export default node;

import { FC, ReactNode, useEffect, useState } from 'react';
import { EditFormProps } from '../data';
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SxProps,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ConsumerNameCheck from '../ConsumerNameCheck';
import RegionalFormatSelect from '../../Select/RegionalFormatSelect';
import { CustomFormat } from '../../../common/types';
import TimezoneAutocomplete from '../../Select/TimezoneAutocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListData } from '../../PaginatedList';
import PaginatedListSelect from '../../Select/PaginatedListSelect';
import CompanyPaginatedList from '../../PaginatedList/CompanyPaginatedList';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { getCountryLanguageFromLocale } from '../../../common/regional';
import ReadonlyInfoItem from './ReadonlyInfoItem';
import { confirmConsumerInactivation } from '../../DataTable/helpers';

const styles = {
    field: {
        width: '100%',
    } as SxProps<Theme>,
    select: {
        width: '100%',
        marginTop: '16px',
    } as SxProps<Theme>,
    companyButton: {
        display: 'flex',
        justifyContent: 'center',
    } as SxProps<Theme>,
    companyDescription: {
        maxHeight: '38px',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    } as SxProps<Theme>,
    deleteCompany: {
        display: 'flex',
        justifyContent: 'flex-end',
    } as SxProps<Theme>,
    icon: (theme => ({
        fontSize: '64px',
        color: theme.palette.text.disabled,
    })) as SxProps<Theme>,
    readonlyHeaderContainer: {
        display: 'flex',
        gap: '16px',
        paddingBottom: '24px',
    } as SxProps<Theme>,
    readonlyName: {
        fontSize: '20px',
        fontWeight: 'bold',
        paddingBottom: '4px',
    } as SxProps<Theme>,
};

type ProfileProps = Pick<
    EditFormProps,
    'initial'
    | 'data'
    | 'setData'
    | 'validate'
    | 'readonly'
    | 'setErrors'
> & { short?: boolean };

const Profile: FC<ProfileProps> = ({
    initial,
    data,
    setData,
    validate,
    readonly,
    setErrors,
    short,
}) => {
    const { name: initialName } = initial;
    const {
        name,
        active,
        requestLimit,
        optimisticPaidOff,
        locale,
        customFormat,
        timezone,
        description,
        company,
    } = data;
    const reqLimitMaxLength = 9;

    const [confirmation, setConfirmation] = useState<ReactNode | undefined>();
    const [hasName, setHasName] = useState(!!name);
    const [isInitialName, setIsInitialName] = useState(true);
    const [nameUsed, setNameUsed] = useState(false);
    const [nameError, setNameError] = useState<string | undefined>();

    useEffect(() => {
        setHasName(!!name);
        setIsInitialName(name === initialName);
    }, [name, initialName]);

    useEffect(() => {
        setNameError(
            nameUsed
                ? 'Consumer name is already being used'
                : hasName
                    ? undefined
                    : 'Name must be filled out before submitting the form',
        );
    }, [hasName, nameUsed]);

    useEffect(
        () => setErrors?.([nameError].filter(e => !!e) as string[]),
        [nameError, setErrors],
    );

    if (readonly) {
        const countryName = locale
            ? getCountryLanguageFromLocale(locale)?.[0]?.name
            : undefined;

        return <Box maxWidth='650px'>
            <Box sx={ styles.readonlyHeaderContainer }>
                <AssignmentIndIcon sx={ styles.icon} />
                <Box>
                    <Typography sx={ styles.readonlyName }>
                        { name }
                    </Typography>
                    <Typography>
                        { description }
                    </Typography>
                </Box>
            </Box>
            <ReadonlyInfoItem
                title='Request Limit'
                value={ requestLimit.toString() }
            />
            <ReadonlyInfoItem
                title='Optimistic Paid Off'
                value={ optimisticPaidOff ? 'True' : 'False' }
            />
            <ReadonlyInfoItem
                title='Regional Settings'
                value={ countryName || '' }
            />
            <ReadonlyInfoItem
                title='Timezone'
                value={ timezone || '' }
            />
            <ReadonlyInfoItem
                title='Company'
                value={ company?.name || '' }
            />
        </Box>;
    }

    return <Grid container spacing='24px'>
        <Grid item xs={ 12 } md={ 6 }>
            <TextField
                sx={ styles.field }
                variant={ short ? 'filled' : 'standard' }
                InputLabelProps={ short ? {
                    shrink: true,
                } : {}}
                label='Name'
                placeholder='Name'
                value={ name }
                onChange={ e => setData({ ...data, name: e.target.value }) }
                error={ (validate && !!nameError) || nameUsed }
                helperText={ validate || nameUsed ? nameError : undefined }
                InputProps={{
                    endAdornment: (() => {
                        if (name) {
                            if (!isInitialName) {
                                return <ConsumerNameCheck
                                    name={ name }
                                    onChecked={ setNameUsed }
                                />;
                            }
                        } else {
                            if (validate) {
                                return <InputAdornment position='end'>
                                    <ErrorOutlineIcon color='error' />
                                </InputAdornment>;
                            }
                        }
                    })()
                }}
            />
        </Grid>
        { !short && <Grid item xs={ 12 } md={ 6 }>
            <Select
                sx={ styles.select }
                variant={ 'standard' }
                value={ active ? 'true' : 'false' }
                onChange={ async e => {
                    const active = e.target.value === 'true';

                    if (active || await confirmConsumerInactivation({
                        setConfirmation,
                        ids: [data.id],
                        action: 'deactivate',
                    })) {
                        setData({ ...data, active });
                    }
                } }
            >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
            </Select>
        </Grid> }
        <Grid item xs={ 12 } md={ 6 }>
            <TextField
                sx={ styles.field }
                variant={ short ? 'filled' : 'standard' }
                inputProps={{ min: 0, step: 1 }}
                label='Request Limit'
                placeholder='Request Limit'
                type='number'
                value={ requestLimit }
                InputLabelProps={ short ? {
                    shrink: true,
                } : {}}
                onChange={ e => {
                    const value = e.target.value as string;

                    if (value.length <= reqLimitMaxLength
                        || (value.length === (reqLimitMaxLength + 1)
                            && value[0] === '0'
                        )
                    ) {
                        const parsed = value === ''
                            ? 0
                            : parseInt(value.replace(/\D/g, ''));

                        if (parsed >= 0) {
                            setData({ ...data, requestLimit: parsed });
                        }
                    }
                } }
            />
        </Grid>
        { !short && <Grid item xs={ 12 } md={ 6 }>
            <FormControl sx={ styles.field }>
                <InputLabel variant='standard'>
                    Optimistic Paid Off
                </InputLabel>
                <Select
                    sx={ styles.select }
                    variant='standard'
                    labelId='optimistic-selector-label'
                    label='Optimistic Paid Off'
                    value={ optimisticPaidOff ? 'true' : 'false' }
                    onChange={ e => setData({
                        ...data,
                        optimisticPaidOff: e.target.value === 'true',
                    }) }
                >
                    <MenuItem value='true'>Optimistic</MenuItem>
                    <MenuItem value='false'>Pessimistic</MenuItem>
                </Select>
            </FormControl>
        </Grid> }
        <Grid item xs={ 12 } md={ 6 }>
            <RegionalFormatSelect
                locale={ locale }
                customFormat={ customFormat as CustomFormat }
                onLocaleChange={ value => setData({ ...data, locale: value }) }
                onCustomFormatChange={ value => setData({
                    ...data,
                    customFormat: value as any
                }) }
            />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
            <TimezoneAutocomplete
                sx={ styles.field }
                value={ timezone }
                onChange={ value => setData({ ...data, timezone: value }) }
            />
        </Grid>
        <Grid item xs={ 12 }>
            <TextField
                sx={ styles.field }
                multiline
                rows={ 3 }
                variant={ short ? 'filled' : 'outlined' }
                label={ 'Description' }
                placeholder={ 'Add description...' }
                InputLabelProps={ short ? {
                    shrink: true,
                } : {}}
                value={ description }
                onChange={ e => setData({
                    ...data,
                    description: !e.target.value && !initial.description
                        ? initial.description
                        : e.target.value,
                }) }
            />
        </Grid>
        { !short && <CompanySelectItem
            company={ company as ListData }
            onCompanySelect={ value =>
                setData({ ...data, company: value as any })
            }
            onDelete={ () => setData({
                ...data,
                company: !initial.company ? initial.company : undefined,
            }) }
        /> }
        { confirmation }
    </Grid>;
};

export interface CompanySelectItemProps {
    company: ListData | null;
    onCompanySelect: (data: ListData | null) => void;
    onDelete: () => void;
}

const CompanySelectItem: React.FunctionComponent<CompanySelectItemProps> = (
    { company, onCompanySelect, onDelete },
) => {
    return <>
        <Grid item xs={ 5 }>
            <PaginatedListSelect
                name='company'
                item={ company }
                onItemSelect={ onCompanySelect }
                Component={ CompanyPaginatedList }
            />
        </Grid>
        <Grid item xs={ 5 }>
            { company && company.description && <Tooltip
                title={ company.description }
            >
                <Box sx={ styles.companyDescription }>
                    { company.description }
                </Box>
            </Tooltip> }
        </Grid>
        { company && <Grid item xs={ 2 }>
            <Box sx={ styles.deleteCompany }>
                <Box sx={ styles.companyButton }>
                    <Tooltip title='Delete related company'>
                        <IconButton onClick={ onDelete }>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Grid> }
    </>;
};

export default Profile;

/**
 * @generated SignedSource<<eaab32446d465c610db4f6c09fddaab4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ParamValueType = "Bool" | "Float" | "Int" | "String" | "%future added value";
export type ServiceConfigValueType = "Array" | "Boolean" | "Float" | "Int" | "Json" | "String" | "%future added value";
export type WizardDataProviderQuery$variables = Record<PropertyKey, never>;
export type WizardDataProviderQuery$data = {
  readonly wizardInstallationInfo: {
    readonly companyCategories: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly features: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: string;
      readonly name: string;
      readonly parents: ReadonlyArray<{
        readonly id: string;
      }> | null | undefined;
    }>;
    readonly integrations: ReadonlyArray<{
      readonly headers: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null | undefined;
      readonly id: string;
      readonly name: string;
      readonly params: ReadonlyArray<{
        readonly description: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly type: ParamValueType;
      }> | null | undefined;
      readonly type: string;
    }>;
    readonly ip: string | null | undefined;
    readonly serviceConfigs: ReadonlyArray<{
      readonly defaultValue: string;
      readonly description: string | null | undefined;
      readonly displayedName: string | null | undefined;
      readonly group: string;
      readonly id: string;
      readonly max: number | null | undefined;
      readonly min: number | null | undefined;
      readonly name: string;
      readonly possibleValues: string | null | undefined;
      readonly type: ServiceConfigValueType;
      readonly value: string | null | undefined;
    }>;
  } | null | undefined;
};
export type WizardDataProviderQuery = {
  response: WizardDataProviderQuery$data;
  variables: WizardDataProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "WizardInstallationInfo",
    "kind": "LinkedField",
    "name": "wizardInstallationInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ip",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyCategory",
        "kind": "LinkedField",
        "name": "companyCategories",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Feature",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Feature",
            "kind": "LinkedField",
            "name": "parents",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Integration",
        "kind": "LinkedField",
        "name": "integrations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Header",
            "kind": "LinkedField",
            "name": "headers",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Param",
            "kind": "LinkedField",
            "name": "params",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceConfig",
        "kind": "LinkedField",
        "name": "serviceConfigs",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayedName",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "max",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "min",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "possibleValues",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "group",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WizardDataProviderQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WizardDataProviderQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "8236b8c262b0a472152379eed88c14a4",
    "id": null,
    "metadata": {},
    "name": "WizardDataProviderQuery",
    "operationKind": "query",
    "text": "query WizardDataProviderQuery {\n  wizardInstallationInfo {\n    ip\n    companyCategories {\n      id\n      name\n    }\n    features {\n      id\n      name\n      description\n      parents {\n        id\n      }\n    }\n    integrations {\n      id\n      name\n      type\n      headers {\n        id\n        name\n      }\n      params {\n        id\n        name\n        type\n        description\n      }\n    }\n    serviceConfigs {\n      id\n      name\n      displayedName\n      description\n      max\n      min\n      possibleValues\n      defaultValue\n      value\n      type\n      group\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd139298ed5d840103d8dfb4ce146cba";

export default node;
